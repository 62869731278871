import { Grid, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

const ProfilingCapital = (props) => {
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    console.log("INIT ProfilingCapital");
    return () => {
      // Perform cleanup tasks here before the component unmounts
      console.log('Component ProfilingCapital will unmount');
    };
  }, [])

  const handleChange = (e) => {
    const value = e.target.value
    setSelected(value)
    props.handleChange(props.q, [value])
    props.handleStatus(props.q, value > 0 ? true : false)
  }

  return (
    <Box sx={{ py: 2 }}>
      <Typography gutterBottom variant='body2'>{t('q_1')}</Typography>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={4} md={4} lg={3}>
          <TextField
            sx={{ my: 1, minWidth: 300 }}
            size='medium'
            label={t('portfolioStat_CapInit')}
            type='number'
            value={selected}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default withTranslation()(ProfilingCapital);