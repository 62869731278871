import React, { Component } from 'react';
import SkeletonChart from '../elements/SkeletonChart';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material'
import DialogHelp from '../elements/DialogHelp';
import I18n from '../commons/Utils';
import numeral from 'numeral';
import ReactApexChart from 'react-apexcharts';
import { IconButton, CardHeader, Card, CardContent, Typography, CardActions } from '@mui/material';
import { withTheme } from '@emotion/react';
import { Trans } from 'react-i18next';
import api from '../services/api';

class MzEquityLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      helpOpen: false,
      helpTitle: <I18n prefix={this.props.prefix} type="title"></I18n>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.state.options) {
        this.loadChart()
      }
    }
  }

  componentDidMount() {
    this.loadChart()
  }

  loadChart() {
    
    api.get('v2/mz/wa/equity_line_benchmark/' + this.props.id)
      .then(response => {
        const data = response.data
        var series = data.s
        var names = data.n
        var p_prices = [];
        var b_prices = [];
        for (var i = 0; i < series.length; i++) {
          var d = series[i].d
          p_prices.push({ 'x': d, 'y': series[i].pv * 1 })
          b_prices.push({ 'x': d, 'y': series[i].bv * 1 })
        }

        var series_data = [
          {
            name: names.p,
            data: p_prices
          },
          {
            name: names.b,
            data: b_prices
          }
        ];

        var chart_options = {
          series: series_data,
          chart: {
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            width: '100%',
            height: '100%',
            type: "line",
            stacked: false
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            itemMargin: {
              vertical: 16
            },
          },
          animations: {
            enabled: true,
            easing: 'easein',
            speed: 250,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          dataLabels: {
            enabled: false
          },
          colors: [this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary],
          stroke: {
            width: [2, 1],
            curve: 'smooth',
          },
          grid: {
            borderColor: this.props.theme.palette.grid,
          },
          xaxis: {
            type: 'datetime',
            labels: {
              show: true,
              rotate: 0,
              style: {
                colors: this.props.theme.palette.text.primary,
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
          },
          yaxis: {
            min: function (min) { return min },
            tickAmount: 3,
            forceNiceScale: false,
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0.[00]') },
              style: {
                colors: '#848484',
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          tooltip: {
            shared: false,
            x: { show: false },
            y: {
              formatter: function (val) {
                return numeral(val).format('0.0[00]');
              }
            }
          },
        };
        this.setState({ chart_options })
      }).catch(error => {
        console.log('NO CHART', error);
        this.state.chart_options = 0
      })
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  render() {
    const { chart_options } = this.state;
    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MzEquityLineTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              MzEquityLineHelp
            </Trans>
          }
        />

        <Card variant="outlined" className='fullHeightCard'>
          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2"><Trans>MzEquityLineTitle</Trans></Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          />
          <CardContent sx={{minHeight: 400}}>
            {chart_options ? (
              <>
                <ReactApexChart options={this.state.chart_options} series={this.state.chart_options.series} type='line' height={this.state.chart_options.chart.height}></ReactApexChart>
              </>
            ) : <SkeletonChart></SkeletonChart>}
          </CardContent>
          <CardActions>
          </CardActions>
        </Card>
      </>
    );
  }
}

export default withTheme(MzEquityLine);