import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardHeader } from "@mui/material";
import { Trans } from 'react-i18next';
import api from '../services/api';
import numeral from 'numeral';
import { t } from 'i18next';

const MzMyReturn = ({ idPortfolio,  idAssignment }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    api.get(`v2/mz/wa/desk/portfolios/${idPortfolio}/uid/${idAssignment}/myreturn`)
      .then(response => {
        const data = response.data;
        setData(data);
        localStorage.setItem("TITLE", data.name);
      }).catch(error => {
        console.log(error);
      });
  }, [idPortfolio]);

  return (
    <Card variant="outlined">
      <CardHeader
        title={<Typography gutterBottom variant="overline" component="h2">{t('homeTradesProfitTitle')}</Typography>}
      />
      <CardContent>
        <Typography gutterBottom variant="overline" component="h2">
          {localStorage.getItem('MZPFN')}
        </Typography>
        <Typography className='big' variant="h3" component="h3">
        {data ? numeral(data.perf).format('+0,000.[00]') + '%' : '-'}
        </Typography>
        <Typography gutterBottom variant="caption" color={'secondary'} component="div">
          <Trans>mz_today_label_return</Trans>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MzMyReturn;
