import React, { useState, useEffect, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import MySkeleton from './MySkeleton';
import { withTheme } from '@emotion/react';
import moment from 'moment';
import { Box } from '@mui/material';
import numeral from 'numeral';

const ChartMiniHistogram = ({ performances, theme }) => {
  const [chartOptions, setChartOptions] = useState(null);
  const echartsRef = useRef(null);

  useEffect(() => {
    plot();
  }, [performances]);

  const plot = () => {
    if (performances) {
      const pSeries = performances.portfolio.series;
      const pName = performances.portfolio.name;
      const pPrices = [];
      const bmExists = performances.bm;
      const bSeries = bmExists ? performances.bm.series : [];
      const bName = bmExists ? performances.bm.name : '';
      const bPrices = [];
      const seriesData = [];
      const categories = [];
      const color1 = theme.palette.chartPrimary;
      const color2 = theme.palette.chartPrimaryLight;

      for (let i = 0; i < pSeries.length; i++) {
        const formattedDate = moment(pSeries[i].d.toString()).format('YYYY-MM-DD');
        categories.push(formattedDate);
        seriesData.push({
          value: pSeries[i].v,
          itemStyle: {
            color: i % 2 === 0 ? color1 : color2
          }
        });
      }

      const options = {
        series: [
          {
            data: seriesData,
            type: 'bar',
            barMaxWidth: 40,
            //showBackground: true,
            width: '100%',
            cursor: 'pointer',
            label: {
              fontFamily: 'Roboto',
              color: theme.palette.text.primary,
              position: 'right',
              formatter: '{b}'
            },
            itemStyle: {
              borderRadius: [4, 4, 0, 0],
            }
          }
        ],
        animationDurationUpdate: 1100,
        animationEasingUpdate: 'quinticInOut',
        colors: theme.palette.chartPrimary,
        title: {
          show: false
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
            formatter: function (params) {
              return `${params[0].axisValueLabel}: <b>${numeral(params[0].value).format('0,000')}€</b>`;
            }
        },
        grid: {
          borderColor: theme.palette.grid,
        },
        xAxis: {
          type: 'category',
          data: categories,
          axisTick: {
            alignWithLabel: true
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          show: true,
          splitNumber: 3,
          margin: 50,
          type: 'value',
          min: value => value.min * 0.95,
          axisLabel: {
            show: false,
            formatter: function (value, index) {
              return numeral(value).format('0.0a');
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed', // Set the line style to dashed
            },
          },
        },
      };

      setChartOptions(options);
    }
  };

  return (
    <Box>
      {chartOptions && chartOptions.series && chartOptions.series.length > 0 ? (
        <ReactECharts
          ref={echartsRef}
          style={{ height: 280, width: '100%' }}
          option={chartOptions}
        />
      ) : (
        <MySkeleton type={'chart'} animation={false} />
      )}
    </Box>
  );
};

export default withTheme(ChartMiniHistogram);
