import React, { useState, useEffect } from 'react';
import SkeletonChart from '../elements/SkeletonChart';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import DialogHelp from '../elements/DialogHelp';
import I18n from '../commons/Utils';
import numeral from 'numeral';
import { IconButton, CardHeader, Card, CardContent, Typography, CardActions, Chip } from '@mui/material';
import { withTheme } from '@emotion/react';
import { Trans } from 'react-i18next';
import api from '../services/api';
import ReactECharts from 'echarts-for-react';
import { pink, purple } from '@mui/material/colors';

const MzMyTreemap = (props) => {
  const [chartOptions, setChartOptions] = useState(null);
  const [helpOpen, setHelpOpen] = useState(false);
  const [treemapSource, setTreemapSource] = useState('market');

  useEffect(() => {
    getAllocation(treemapSource);
  }, [props.id]);

  useEffect(() => {
    getAllocation(treemapSource);
  }, [treemapSource]);

  const getAllocation = (type) => {
    numeral.options.scalePercentBy100 = false;
    type = type ? type : 'market';

    api.get('v2/mz/wa/portfolio_allocation_' + type + '/' + props.idPortfolio)
      .then(response => {
        const data = response.data;
        const dataSectors = data.sectors;
        const series = [];
        const labels = [];
        const items = [];
        const color1 = props.theme.palette.chartPrimary;
        const color2 = props.theme.palette.chartPrimaryLight;
        const colors = [
          "#5A287F",
          "#69328D",
          "#793E9A",
          "#8848A7",
          "#9853B4",
          "#A75FBE",
          "#B66ACB",
          "#C475D8",
          "#D481E5",
          "#E38CF2",
          "#F298FF",
          "#FFA3FF",
          "#FFADFF",
          "#FFB8FF",
          "#FFC2FF",
          "#FFCCFF",
          "#FFD6FF",
          "#FFE1FF",
          "#FFEBFF",
          "#FFF5FF",
          "#FFFFFF",
          "#FFFFFF",
          "#2E0854",
          "#3C1363",
          "#4B1D72",
        ];

        for (let i = 0; i < dataSectors.length; i++) {
          series.push(parseFloat(dataSectors[i].allocation));
          labels.push(dataSectors[i].group_name);
          items.push({
            name: dataSectors[i].group_name,
            value: parseFloat(dataSectors[i].allocation),
            itemStyle: {
              color: colors[i]
            }
          })
        }

        const options = {
          series: [
            {
              type: 'treemap',
              data: items
            }
          ],
          tooltip: {
            formatter: function (info) {
              return [
                '<div class="tooltip-title"><b>' +
                info.data.name +
                '</b></div>',
                numeral(info.data.value).format('0.00') + ' %'
              ].join('');
            }
          },

        };

        setChartOptions(options);
      }).catch(error => {
        console.log(error);
      });
  };

  const closeHelpDialog = () => {
    setHelpOpen(false);
  };

  const openHelpDialog = () => {
    setHelpOpen(true);
  };

  const handleSource = (id) => {
    setTreemapSource(id)
  };

  return (
    <>
      <DialogHelp
        closeHelpDialog={closeHelpDialog}
        isHelpDialogOpen={helpOpen}
        helpDialogTitle={<Trans>MzEquityLineTitle</Trans>}
        helpDialogContent={
          <Trans
            components={{
              b: <strong />,
              em: <em />,
            }}
          >
            MzEquityLineHelp
          </Trans>
        }
      />

      <Card variant="outlined" className='fullHeightCard'>
        <CardHeader
          title={<Typography gutterBottom variant="overline" component="h2"><Trans>MzAllocationTitle</Trans></Typography>}
          // action={
          //   <IconButton aria-label="settings" onClick={openHelpDialog}>
          //     <HelpOutlineOutlinedIcon />
          //   </IconButton>
          // }
        />
        <CardContent sx={{ minHeight: 400 }}>
          {chartOptions ? (
            <ReactECharts
              style={{ height: 400, width: '100%' }}
              option={chartOptions}
            />
          ) : <SkeletonChart sx={{height: 400}} />}
        </CardContent>
        <CardActions>
          {[{ id: 'market', label: 'Markets' }, { id: 'sector', label: 'Setcors' }].map(obj =>
            <Chip clickable size='small' label={obj.label}
              color={obj.id === treemapSource ? 'primary' : 'secondary'}
              variant={obj.id === treemapSource ? 'filled' : 'outlined'}
              sx={{ minWidth: 100 }}
              key={"chipSource_" + obj.id} onClick={() => handleSource(obj.id)} />
          )}
        </CardActions>
      </Card>
    </>
  );
};

export default withTheme(MzMyTreemap);
