import React, { useState, useEffect } from 'react';
import SkeletonChart from '../elements/SkeletonChart';
import { BarChart, HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import DialogHelp from '../elements/DialogHelp';
import I18n from '../commons/Utils';
import numeral from 'numeral';
import ReactECharts from 'echarts-for-react';
import { IconButton, CardHeader, Card, CardContent, Typography, CardActions, Skeleton } from '@mui/material';
import { withTheme } from '@emotion/react';
import { Trans } from 'react-i18next';
import api from '../services/api';
import moment from 'moment';
import { Box } from '@mui/system';
import { t } from 'i18next';

const MzMyEquityLine = (props) => {
  const [chartOptions, setChartOptions] = useState(null);
  const [helpOpen, setHelpOpen] = useState(false);
  const [helpTitle, setHelpTitle] = useState(<I18n prefix={props.prefix} type="title"></I18n>);
  const [helpText, setHelpText] = useState(<I18n prefix={props.prefix} type="help"></I18n>);
  const [loading, setLoading] = useState(null);


  useEffect(() => {
    loadChart();
  }, [props.id]);

  const loadChart = () => {
    setLoading(true)
    api.get(`v2/mz/wa/desk/portfolios/${props.idPortfolio}/uid/${props.idAssignment}/myeqline`)
      .then(response => {
        const data = response.data;
        const series = data;
        const seriesData = [];
        const categories = [];
        const color1 = props.theme.palette.chartPrimary;
        const color2 = props.theme.palette.chartPrimaryLight;

        for (let i = 0; i < series.length; i++) {
          const formattedDate = moment(series[i].d.toString()).format('YYYY-MM-DD');
          categories.push(formattedDate);
          seriesData.push({
            value: series[i].v,
            itemStyle: {
              color: i % 2 === 0 ? color1 : color2
            }
          });
        }

        const options = {
          series: [
            {
              data: seriesData,
              type: 'bar',
              width: '100%',
              cursor: 'pointer',
              label: {
                fontFamily: 'Roboto',
                color: props.theme.palette.text.primary,
                position: 'right',
                formatter: '{b}'
              },
              itemStyle: {
                borderRadius: [8, 8, 0, 0],
              }
            }
          ],
          animationDurationUpdate: 1100,
          animationEasingUpdate: 'quinticInOut',
          colors: props.theme.palette.chartPrimary,
          title: {
            show: false
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: function (params) {
              return `${params[0].axisValueLabel}<br/> <b>${numeral(params[0].value).format('0,000')}€</b>`;
            }
          },
          grid: {
            borderColor: props.theme.palette.grid,
            left: 32,
            right: 32,
            // bottom: 0,
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: categories,
            axisTick: {
              alignWithLabel: true
            },
            splitLine: {
              show: false,
            },
          },
          yAxis: {
            show: true,
            type: 'value',
            min: value => Math.round(value.min * 0.95),
            axisLabel: {
              show: true,
              // formatter: (value, index) => {
              //   return numeral(value).format('0.0a');
              // }
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed', // Set the line style to dashed
              },
            },
          },
        };

        setChartOptions(options);
        setLoading(false)
      }).catch(error => {
        console.log('NO CHART', error);
        setLoading(false)
        setChartOptions(null);
      });
  };

  const closeHelpDialog = () => {
    setHelpOpen(false);
  };

  const openHelpDialog = () => {
    setHelpOpen(true);
  };

  return (
    <>
      <DialogHelp
        closeHelpDialog={closeHelpDialog}
        isHelpDialogOpen={helpOpen}
        helpDialogTitle={<Trans>MzEquityLineTitle</Trans>}
        helpDialogContent={
          <Trans
            components={{
              b: <strong />,
              em: <em />,
            }}
          >
            MzEquityLineHelp
          </Trans>
        }
      />

      <Card variant="outlined" className='fullHeightCard'>
        <CardHeader
          title={<Typography gutterBottom variant="overline" component="h2"><Trans>MzDeskEquityLineTitle</Trans></Typography>}
          // action={
          //   <IconButton aria-label="settings" onClick={openHelpDialog}>
          //     <HelpOutlineOutlinedIcon />
          //   </IconButton>
          // }
        />
        <CardContent sx={{ minHeight: 400 }}>
          {
            loading ? <Skeleton sx={{ height: 400 }} /> :
              chartOptions && chartOptions.series[0].data.length > 0 ? (
                <ReactECharts
                  style={{ height: 400, width: '100%' }}
                  option={chartOptions}
                />
              ) :
                <Box sx={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <BarChart color='secondary' sx={{ fontSize: 55 }} />
                  <Typography variant='body2' className='bold'> {t('desk_empty_title')} </Typography>
                  <Typography variant='caption' color={'textSecondary'}>{t('desk_empty_title2')}</Typography>
                </Box>
          }
        </CardContent>
      </Card>
    </>
  );
};

export default withTheme(MzMyEquityLine);
