import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Chip, Skeleton } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { CheckOutlined } from '@mui/icons-material';
import api from '../services/api';

const ProfilingMarkets = ({ handleChange, handleStatus, q, t }) => {
  const [selected, setSelected] = useState([]);
  const [marketAreas, setMarketAreas] = useState(null);
  const [loading, setLoading] = useState(false);
  const loadingElements = 16

  const loadMarketAreas = () => {
    setLoading(true)
    api.get('v2/mz/wa/markets', { cache: false })
      .then(response => {
        const marketAreas = response.data;
        setMarketAreas(marketAreas);
        setLoading(false)
      })
      .catch(error => {
        console.log(error);
        setLoading(false)
      });
  };

  useEffect(() => {
    loadMarketAreas();
  }, []);

  const handleChipClick = value => {
    let updatedSelected = [...selected];
    if (!updatedSelected.includes(value)) {
      updatedSelected.push(value);
    } else {
      updatedSelected = updatedSelected.filter(v => v !== value);
    }
    setSelected(updatedSelected);
    handleChange(q, updatedSelected);
    handleStatus(q, updatedSelected.length > 0);
  };

  return (
    <Box sx={{ py: 2 }}>
      <Typography gutterBottom variant='body2'>{t('q_3')}</Typography>
      <Grid container spacing={1}>
        {
          loading ?
              Array.from({ length: loadingElements }, (_, i) =>
                <Grid item xs={6} sm={4} md={4} lg={3} key={'empty_' + i}>
                  <Skeleton sx={{ height: 32, transform: 'none', m: 1 }} />
                </Grid>
              )
            :
            marketAreas && !loading ? marketAreas.map(item => {
              const checked = selected.includes(item.v);
              return (
                <Grid item xs={6} sm={4} md={4} lg={3} key={t(item.l)}>
                  <Chip
                    sx={{ m: 1, minWidth: "100%" }}
                    size='medium'
                    variant={checked ? 'plain' : 'filled'}
                    color={checked ? 'primary' : 'default'}
                    label={t(item.l)}
                    icon={checked ? <CheckOutlined /> : ''}
                    onClick={() => {
                      handleChipClick(item.v);
                    }}
                  />
                </Grid>
              )
            }) : <Box sx={{ p: 2 }}>Data not found</Box>}
      </Grid>
    </Box>
  );
};

export default withTranslation()(ProfilingMarkets);
