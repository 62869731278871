import { ChevronLeft } from '@mui/icons-material';
import { Box, Chip, Container, Fab, Fade, Grid, Toolbar, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { BrowserView } from 'react-device-detect';
import { NavLink, useParams } from 'react-router-dom';
import MzAllocation from '../cards/MzAllocation';
import MzDrawdown from '../cards/MzDrawdown';
import MzEquityLine from '../cards/MzEquityLine';
import MzInfo from '../cards/MzInfo';
import MzPerformances from '../cards/MzPerformances';
import MzReturn from '../cards/MzReturn';
import MzRiskAnalysis from '../cards/MzRiskAnalysis';
import MzMyReturn from '../cards/MzMyReturn';
import MzMyCapital from '../cards/MzMyCapital';
import MzMyEquityLine from '../cards/MzMyEquityLine';
import MzMyTreemap from '../cards/MzMyTreemap';
import MzAllocationDetails from '../cards/MzAllocationDetails';
import DeskMzAllocationDetails from '../cards/DeskMzAllocationDetails';
import api from '../services/api';

const Portfolios = (props) => {
  const {idPortfolio, idAssignment} = useParams();
  const [id, setId] = useState(0);
  const [mobileView, setMobileView] = useState(1);
  const [view, setView] = useState(0);
  const [data, setData] = useState([]);

  const parentRef = useRef(null);

  const backToPortfolios = () => {
    window.location.href = '/portfolios';
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component is loaded
    api.get(`v2/mz/wa/desk/portfolios/${idPortfolio}/uid/${idAssignment}/info`)
      .then(response => {
        const data = response.data;
        setData(data);
      }).catch(error => {
        console.log(error);
      });
  }, [idPortfolio]);

  const switchMobileView = (view) => {
    setMobileView(view);
  };

  const handleView = (id) => {
    setView(id)
  };

  return (
    <>
      {idPortfolio && idPortfolio > 0 ? (
        <Fade in={true} timeout={450}>
          <div>
              <Toolbar />
              <Container sx={{ p: 2 }}>
                <Box style={{ display: 'flex', mb: 4, justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex' }}>
                    <NavLink to='/portfolios'>
                      <Tooltip color='error' title='Back to Portfolios' arrow>
                        <Fab sx={{ mr: 3 }} size='small' variant='circular' color='primary'><ChevronLeft /></Fab>
                      </Tooltip>
                    </NavLink>
                    <Typography variant="h4" className='big'>{data ? data.name : t("mz_portfolio_page_title")}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {[
                      { id: 0, label: t('desk_switch_personal_title') },
                      { id: 1, label: t('desk_switch_global_title') }
                    ].map(obj =>
                      <Chip clickable size='small' label={obj.label}
                        color={obj.id === view ? 'primary' : 'secondary'}
                        variant={obj.id === view ? 'filled' : 'outlined'}
                        sx={{ minWidth: 120 }}
                        key={"chipView_" + obj.id} onClick={() => handleView(obj.id)} />
                    )}
                  </Box>
                </Box>
                <Box sx={{ display: view === 0 ? 'block' : 'none' }}>
                  <Grid container spacing={1} direction="row" alignItems="stretch" sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                      <MzMyReturn idPortfolio={idPortfolio} idAssignment={idAssignment}prefix="mz_today" />
                    </Grid>
                    <Grid item xs={6}>
                      <MzMyCapital idPortfolio={idPortfolio} idAssignment={idAssignment}prefix="mz_today" />
                    </Grid>
                    <Grid item xs={12}>
                      <MzMyEquityLine idPortfolio={idPortfolio} idAssignment={idAssignment}/>
                    </Grid>
                    <Grid item xs={6}>
                      <MzMyTreemap idPortfolio={idPortfolio} idAssignment={idAssignment}/>
                    </Grid>
                    <Grid item xs={6}>
                      <DeskMzAllocationDetails idPortfolio={idPortfolio} idAssignment={idAssignment}rows={1000} />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ display: view === 1 ? 'block' : 'none' }}>
                  <Grid container spacing={1} direction="row" alignItems="stretch" sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                      <MzReturn id={idPortfolio} prefix="mz_today"></MzReturn>
                    </Grid>
                    <Grid item xs={6}>
                      <MzInfo id={idPortfolio} prefix="mz_today" />
                    </Grid>
                    <Grid item xs={12}>
                      <MzEquityLine id={idPortfolio} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={7}>
                      <MzAllocation id={idPortfolio} prefix="mz_portfAllocation"></MzAllocation>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={5}>
                      <MzPerformances id={idPortfolio} prefix="mz_performance"></MzPerformances>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={5}>
                      <MzRiskAnalysis id={idPortfolio} prefix="mz_risk"></MzRiskAnalysis>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={7}>
                      <MzDrawdown id={idPortfolio} prefix="mz_drawdown"></MzDrawdown>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
          </div>
        </Fade>
      ) : 'loading...'}
    </>
  );
};

export default Portfolios;
