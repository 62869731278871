import { ArrowDownwardOutlined, ArrowUpwardOutlined, SwapVert } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Card, CardActionArea, CardContent, CardHeader, Container, Fade, Grid, Menu, MenuItem, Skeleton, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { withTheme } from '@emotion/react';
import { BarChart } from '@mui/icons-material';
import { t } from 'i18next';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import ChartMiniHistogram from '../elements/ChartMiniHistogram';
import IntroMyPortfolios from '../intro/IntroMyPortfolios';
import { notifyLogout, setMainTitle } from '../reduxActions/Actions';
import api from '../services/api';

const PortfoliosOverview = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null)
  const [introOpen, setIntroOpen] = useState(false) //useState(localStorage.getItem("ONB_MYPORTFOLIOS") ? false : true)
  const [menuAnchor, setMenuAnchor] = useState(null);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const menuOptions = [
    { id: 'default', label: selectedIndex === 0 ? t('desk_sortDefault') : 'Reset' },
    { id: 'startDate', label: t('desk_sortStartDate') },
    { id: 'perf', label: t('desk_sortPerf') },
    { id: 'name', label: t('desk_sortName') },
  ]
  const [menuOpen, setMenuOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem("TOKEN")) {
      props.dispatch(notifyLogout())
    }

    props.dispatch(setMainTitle("Portfolios"))

    loadPortfolios()
  }, [])

  const loadPortfolios = () => {
    setLoading(true)
    const idUser = localStorage.getItem('ID')
    api.get(`v2/mz/wa/users/${idUser}/portfolios`)
      .then(response => {
        const resp = response.data
        const color1 = props.theme.palette.chartPrimary
        if (resp.length === 0) {
          navigate('/builder')
        }
        setData(resp)
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        console.log(error);
      })
  }

  const openSortMenu = (e) => {
    setMenuAnchor(e.currentTarget)
    setMenuOpen(true)
  }
  const toggleSortDirection = () => {
    if (selectedIndex) { // do nothing if default sorting is selected
      const newDir = !sortDirection
      setMenuOpen(false)
      setSortDirection(newDir)
      sort(selectedIndex, newDir)
    }
  }

  const handleSortMenuItem = (id) => {
    //const newDir = false
    sort(id, sortDirection)
    setMenuAnchor(null)
    setMenuOpen(false)
    setSelectedIndex(id)
  }

  const sort = (i, direction) => {
    if (i === 0) loadPortfolios() // default sorting
    else {
      let portfolios = data
      let sortDirection = direction
      let sortCriteria = menuOptions[i].id
      if (sortDirection) {
        portfolios.sort(function (a, b) {
          if (!isNaN(a.ptf[sortCriteria])) {
            return a.ptf[sortCriteria] - b.ptf[sortCriteria]
          } else {
            return a.ptf[sortCriteria].localeCompare(b.ptf[sortCriteria])
          }
        })
      } else {
        portfolios.sort(function (a, b) {
          if (!isNaN(a.ptf[sortCriteria])) {
            return b.ptf[sortCriteria] - a.ptf[sortCriteria]
          } else {
            return b.ptf[sortCriteria].localeCompare(a.ptf[sortCriteria])
          }
        })
      }
      setData(portfolios)
    }
  }

  const handleClose = () => {
    setMenuAnchor(null)
    setMenuOpen(false)
  }

  const introClose = () => {
    setIntroOpen(false)
  }

  return (
    <Fade in={true} timeout={450}>
      <div>
        <Toolbar />
        <Container sx={{ p: 2 }}>
          <IntroMyPortfolios
            open={introOpen}
            introClose={introClose}
          />
          <Grid container item xs={12} sm={12} md={12} lg={12} spacing={1}>
            <Grid item xs={6}>
              <Box sx={{ pb: 2 }}>
                <Typography variant="h4" className='big'>My Portfolios</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ textAlign: 'right' }}>
                <ButtonGroup variant="outlined" disableElevation size="small" disabled={!data || data.length === 0}>
                  <Button
                    onClick={openSortMenu}
                  >
                    {selectedIndex === 0 ? t('desk_sort1') : t('desk_sortBy') + " " + menuOptions[selectedIndex].label}
                  </Button>
                  <Button
                    onClick={toggleSortDirection}>
                    {selectedIndex === 0 ? <SwapVert /> : sortDirection ? <ArrowUpwardOutlined color="default" /> : <ArrowDownwardOutlined color="default" />}
                  </Button>
                </ButtonGroup>
                <Menu
                  variant="menu"
                  sx={{ marginTop: 1, minWidth: 250 }}
                  id="lock-menu"
                  anchorEl={menuAnchor}
                  open={menuOpen}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                  }}
                >
                  {menuOptions.map((option, index) => (
                    <MenuItem
                      key={option.id}
                      selected={index === selectedIndex}
                      onClick={(event) => handleSortMenuItem(index)}
                    >
                      <Typography variant="body2">{option.label}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1}>

            {
              loading ?
                Array.from({ length: 4 }, (_, i) =>
                  <Grid key={'skeleton_' + i} xs={12} md={6} lg={6} item>
                    <Skeleton sx={{ height: 358 }} />
                  </Grid>
                )
                :
                data ? data.map((obj, i) => (
                  <Grid key={i} xs={12} md={6} lg={6} item>
                    <Fade in={true} timeout={450}>
                      <Card variant='outlined'>
                        <CardActionArea component={NavLink} to={`/portfolio/${obj.ptf.idPortfolio}/uid/${obj.ptf.idAssignment}`}>
                          <CardHeader
                            sx={{ pb: 0 }}
                            title={<Typography variant='body2' style={{ fontWeight: 700 }}>{obj.ptf.name}</Typography>}
                            subheader={<Typography variant='caption' color="textSecondary" style={{ fontWeight: 300 }}>{t('portfolioStat_CapCurr') + ": € " + numeral(obj.ptf.v || obj.ptf.initialCapital).format('0,000')}</Typography>}
                            action={<Typography variant='h4' className={'big'}>{numeral(obj.ptf.perf).format('+0,000.00')}%</Typography>}
                          />
                          <CardContent sx={{ pt: 0 }}>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ flex: '100%' }}>
                                {obj.eql && obj.eql.portfolio.series.length > 0 ?
                                  <ChartMiniHistogram isLoading={false} performances={obj.eql} />
                                  : <Box sx={{ height: 280, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <BarChart color='secondary' sx={{ fontSize: 55 }} />
                                    <Typography variant='body2' className='bold'> {t('desk_empty_title')} </Typography>
                                    <Typography variant='caption' color={'textSecondary'}>{t('desk_empty_title2')}</Typography>
                                  </Box>
                                }
                              </Box>
                            </Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Fade>
                  </Grid>
                )) : ''}

          </Grid>
        </Container>
      </div>
    </Fade >
  );
}

export default connect()(withTheme(PortfoliosOverview));