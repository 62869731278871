import { AddOutlined } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import DeskMzAllocation from '../cards/DeskMzAllocation';
import DeskMzAllocationDetails from '../cards/DeskMzAllocationDetails';
import DeskMzEquityLine from '../cards/DeskMzEquityLine';
import DeskMzReturn from '../cards/DeskMzReturn';
import api from '../services/api';
import { t } from 'i18next';

function PortfolioPreview({ id, open, handleClose, questions }) {
  const [loading, setLoading] = useState(false);
  const [showName, setShowName] = useState(false);
  const [portfolioName, setPortfolioName] = useState(null);


  const navigate = useNavigate()

  const handleAdd = () => {
    setShowName(true)
  }

  const onClose = () => {
    handleClose()
    setTimeout(() => {
      setShowName(false)
    }, 300);
  }

  const handleChange = (e) => {
    const pName = e.target.value
    setPortfolioName(pName)
  }

  const handleConfirm = () => {
    if (portfolioName) {
      handleSubscribe()
    }
  }

  const handleSubscribe = () => {
    setLoading(true)
    const idUser = localStorage.getItem('ID')
    api.post(`v2/mz/wa/users/${idUser}/portfolios/${id}`, { capital: questions.q_1[0], name: portfolioName })
      .then(response => {
        const data = response.data
        navigate('/portfolios')
      }).catch(error => {
        console.log(error);
        setLoading(false)
      })
  }

  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth='lg' scroll='paper'>
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <DeskMzReturn id={id} prefix="mz_today" />
            </Box>
            <Box>
              {showName ?
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <TextField size={'small'} label='Portfolio name' onChange={handleChange} />
                  <Button color='cta' variant='contained' size='middle' disableElevation onClick={handleConfirm}>Confirm</Button>
                </Box>
                :
                <Button variant='contained' size='medium'
                  onClick={handleAdd}
                  color='cta'
                  disableElevation startIcon={<AddOutlined />}>{t('desk_addToMyPtf')}</Button>
              }
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={1} width={1024} direction="row" alignItems="stretch" sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <DeskMzEquityLine id={id} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <DeskMzAllocation id={id} prefix="mz_portfAllocation" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5}>
              <DeskMzAllocationDetails idPortfolio={id} rows={5} prefix="mz_performance" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PortfolioPreview;
