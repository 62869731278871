import React from 'react';
import { Box, DialogContent, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { DialogActions, Chip } from '@mui/material';
import Button from '@mui/material/Button';
import numeral from 'numeral';
import { Component } from 'react';
import I18n from '../commons/Utils';
import CheckOutlined from '@mui/icons-material/CheckOutlined';
import ReactApexChart from 'react-apexcharts';
import { withTheme } from '@emotion/react';
import api from '../services/api';

class MzPerformancesDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
      param: 'r_today',
      selected: 'r_today',
      options: null,
    }
  }

  handleChange(param) {
    this.setState({ param })
    this.getData();
  }

  getData() {

    api.get('v2/mz/wa/report_details/' + this.props.id)
      .then(response => {
        const pf = response.data[0];
        const bm = response.data[1];
        let data = [];

        var series_data = [
          {
            name: bm.name,
            data: [bm[this.state.param]]
          }, {
            name: pf.name,
            data: [pf[this.state.param]]
          }]

        var options = {
          series: series_data,
          chart: {
            type: 'bar',
            height: 250,
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            offsetX: 0
          },
          colors: [this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary],
          grid: {
            borderColor: this.props.theme.palette.grid,
            yaxis: {
              lines: {
                show: true
              }
            },
          },
          legend: {
            show: true,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 4,
              columnWidth: '70%',
              dataLabels: {
                position: 'top',
              },
            }
          },
          dataLabels: {
            enabled: true,
            offsetY: 10,
            style: {
              fontSize: '12px',
              colors: ['#000'],
            },
            formatter: function (val) {
              return numeral(val).format('+0.00') + '%'
            },
            dropShadow: {
              enabled: true,
              top: 1,
              bottom: 1,
              left: 1,
              right: 1,
              blur: 1,
              color: '#848484',
              opacity: 0.45
            }
          },
          stroke: {
            show: false,
            width: 1,
            colors: ['#fff']
          },
          tooltip: {
            enabled: true,
            intersect: false,
            y: {
              formatter: function (val) {
                return numeral(val).format('+0.00') + '%'
              },
            },
            x: {
              format: 'MMM YYYY',
            },
            marker: {
              show: false,
            },
          },
          yaxis: {
            min: function (min) { return min },
            max: function (max) { return max },
            forceNiceScale: true,
            tickAmount: 2,
            labels: {
              show: false,
              formatter: (value) => { return numeral(value).format('0.[00]') + '%' },
              style: {
                colors: '#848484',
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          xaxis: {
            tickAmount: 5,
            tickPlacement: 'on',
            labels: {
              show: false,
              rotate: 0,
              style: {
                colors: '#848484',
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: true
            },
          },
        };

        this.setState({ data, options });
      }).catch(error => {
        console.log(error);
      })
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && this.props.isDialogOpened) {
      this.getData()
    } else if(prevProps !== this.props && !this.props.isDialogOpened){
      this.setState({ data: [], options: null })
    }
  }

  componentWillUnmount() {
    this.setState({ data: [], options: null })
  }

  render() {
    const { param, options } = this.state;
    const { classes } = this.props;

    return (
      <Dialog id={this.props.id} open={this.props.isDialogOpened} onClose={this.props.closeDialog}>
        <DialogTitle>{<I18n prefix={this.props.prefix} type="title"></I18n>}</DialogTitle>
        <DialogContent >
          <>
            {[
              { 'l': 'TOTAL', 'v': 'r_today' },
              { 'l': '1M', 'v': 'r_1m' },
              { 'l': '3M', 'v': 'r_3m' },
              { 'l': '1Y', 'v': 'r_1y' },
              { 'l': '3Y', 'v': 'r_3y' },
              { 'l': '5Y', 'v': 'r_5y' },
              { 'l': 'YTD', 'v': 'r_ytd' },
            ].map((item) => {
              const checked = param === item.v;
              return (
                <Chip
                  sx={{ mr: 1 }}
                  size='medium'
                  key={item.l}
                  variant={checked ? 'plain' : 'outlined'}
                  color={checked ? 'primary' : 'secondary'}
                  label={item.l}
                  onClick={() => this.handleChange(item.v)}
                >

                </Chip>
              );
            })}
          </>
          {options ? (
            <Box sx={{ p: 2 }}>
              <ReactApexChart options={options} series={options.series} type='bar' height={150} />
            </Box>

          ) : "-"}
        </DialogContent>
        <DialogActions>
          <Button variant='' onClick={this.props.closeDialog} color="primary">
            <CheckOutlined color="primary"></CheckOutlined>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withTheme(MzPerformancesDetails);