import { Alert, Card, CardActionArea, CardContent, CardHeader, CircularProgress, Container, Dialog, Fade, Grid, Skeleton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import api from '../services/api';
import ProfilingCapital from './ProfilingCapital';
import ProfilingMarkets from './ProfilingMarkets';
import ProfilingSectors from './ProfilingSectors';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import ChartMini from '../elements/ChartMini';
import PortfolioPreview from '../elements/PortfolioPreview';

const Builder = (props) => {
  const [activeStep, setActiveStep] = useState(0)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogText, setDialogText] = useState('-')
  const [matched, setMatched] = useState(null);
  const [statuses, setStatuses] = useState({
    q_1: false,
    q_2: false,
    q_3: false,
  })
  const [questions, setQuestions] = useState({
    q_1: 0,
    q_2: 0,
    q_3: 0,
  })
  const [canNext, setCanNext] = useState(true)
  const [steps, setSteps] = useState([
    {
      title: t("Profiling0ShortTitle"),
      label: t("Profiling0Title"),
    },
    {
      title: t("Profiling2ShortTitle"),
      label: t("Profiling2Title"),
    },
    {
      title: t("Profiling3ShortTitle"),
      label: t("Profiling3Title"),
    },
    {
      title: t("Profiling4ShortTitle"),
      label: t("Profiling4Title"),
    },
  ])
  const [selectedPreview, setSetselectedPreview] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [loadingText, setLoadingText] = useState(t("Profilingend_1_text"))
  const [loading, setLoading] = useState(false);
  const loadingElements = 4
  const navigate = useNavigate()

  useEffect(() => {
  }, [])
  useEffect(() => {
  }, [activeStep])

  const handleChange = (question, value) => {
    let _questions = questions
    _questions[question] = value
    setQuestions(_questions)
  }

  const handleStatus = (question, statusValue) => {
    let _statuses = statuses
    _statuses[question] = statusValue
    setStatuses(_statuses)
    setCanNext(statusValue)
  }

  const handleNext = () => {
    console.log(statuses);
    let canStep = statuses['q_' + activeStep]
    if ((activeStep === 0 && !canStep) || canStep) {
      const nextStep = activeStep + 1
      setActiveStep(nextStep);
      canStep = statuses['q_' + nextStep]
      setCanNext(canStep);
    } else {
      openDialog()
    }
  }

  const handleBack = () => {
    console.log(statuses);
    const backStep = activeStep - 1
    const canStep = statuses['q_' + backStep]
    setActiveStep(backStep);
    if(canStep) setCanNext(true);
  }

  const handleSubmit = () => {
    setActiveStep(-1);
    setCanNext(false);

    let payload = questions;
    setLoading(true)
    api.post('v2/mz/wa/build', payload)
      .then(response => {
        const data = response.data
        setTimeout(() => {
          setMatched(data)
          setLoading(false)
        }, 1000);
      }).catch(error => {
        setLoadingText('Something went wrong. Please retry')
        console.log(error);
        setLoading(false)
      })
  }

  const closeDialog = () => {
    setDialogOpen(false)
  }

  const openDialog = (text) => {
    setDialogOpen(true)
    setDialogText(text ? text : "Rispondi alla domanda prima di passare allo step successivo")
  }

  const handleReset = () => {
    setActiveStep(0)
    setDialogOpen(false)
    setDialogText('-')
    setMatched(null)
  }

  const handlePreview = (id) => {
    setSetselectedPreview(id)
    setPreviewOpen(true)
  }

  const handlePreviewClose = () => {
    setPreviewOpen(false)
  }

  return (
    <Fade in={true} timeout={450}>
      <div>
        <Container sx={{ p: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4" className='big'>{t("ProfilingTitle")}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Dialog open={dialogOpen} onClose={() => closeDialog()}>
                <Alert action={<Button onClick={() => closeDialog()} color="inherit">ok</Button>} severity='warning' variant="filled">{dialogText}</Alert>
              </Dialog>
              <Card>
                <CardHeader
                  title={<Typography gutterBottom variant="overline" component="h2">{t("ProfilingSubTitle")}</Typography>}
                ></CardHeader>
                <CardContent>
                  {/* ----- STEPPER HEADER ----- */}
                  <Stepper activeStep={activeStep} sx={{ my: 2 }}>
                    {activeStep < steps.length && steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label.title}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>

                  {/* ----- STEPPER CONTENT ----- */}
                  <Box>
                    <Box sx={{ display: activeStep === 0 ? 'block' : 'none' }}>
                      <Typography sx={{ py: 2 }}>{t("Profiling0SubTitle")}</Typography>
                    </Box>
                    <Box sx={{ display: activeStep === 1 ? 'block' : 'none' }}>
                      <Typography sx={{ py: 2 }}>{t("Profiling2Title")}</Typography>
                      <ProfilingCapital handleChange={handleChange} handleStatus={handleStatus} q={'q_1'} />
                    </Box>
                    <Box sx={{ display: activeStep === 2 ? 'block' : 'none' }}>
                      <Typography sx={{ py: 2 }}>{t("Profiling3Title")}</Typography>
                      <ProfilingMarkets handleChange={handleChange} handleStatus={handleStatus} q={'q_2'} />
                    </Box>
                    <Box sx={{ display: activeStep === 3 ? 'block' : 'none' }}>
                      <Typography sx={{ py: 2 }}>{t("Profiling4Title")}</Typography>
                      <ProfilingSectors handleChange={handleChange} handleStatus={handleStatus} handleError={openDialog} q={'q_3'} />
                    </Box>

                    <Box sx={{ mb: 2 }}>
                      <div>
                        {activeStep > 0 && activeStep < steps.length ?
                          <>
                            <Button
                              disableElevation
                              onClick={() => handleBack()}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {t("ProfilingStartBack_button")}
                            </Button>
                            <Button
                              disabled={!canNext}
                              variant="contained"
                              disableElevation
                              onClick={handleNext}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {activeStep === steps.length - 1 ? t("ProfilingStartFinish_button") : t("ProfilingStartNext_button")}
                            </Button>
                          </>
                          : activeStep === 0 ?
                          <Button
                            variant="contained"
                            color='cta'
                            disableElevation
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            {activeStep === steps.length - 1 ? t("ProfilingStartFinish_button") : t("ProfilingStart_button")}
                          </Button> : ''
                        }
                      </div>
                    </Box>
                  </Box>

                  {/* ----- STEPPER NAVIGATOR ----- */}
                  {activeStep === -1 && (
                    <Button variant='contained' disableElevation onClick={handleReset}>Edit</Button>
                  )}

                  {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                      <Box sx={{ mb: 3 }}>
                        <Typography variant='h6'>{t("ProfilingLast_title")}</Typography>
                        <Typography variant='body2'>{t("ProfilingLast_text")}</Typography>
                      </Box>
                      <Button
                        disableElevation
                        onClick={() => handleBack()}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("ProfilingStartBack_button")}
                      </Button>
                      <Button color='cta' disableElevation variant='contained' onClick={() => handleSubmit()} sx={{ mt: 1, mr: 1 }}>
                        {t("ProfilingLast_button")}
                      </Button>
                    </Paper>
                  )}

                </CardContent>
              </Card>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              {
                (matched || loading) && (
                  <Grid item xs={12}>
                    {matched ? (
                      <Typography variant='h5' className='big'>{t('desk_built_title')}</Typography>
                    ) : (
                      <Typography variant='h5' className='big'>{t('desk_building_title')}</Typography>
                    )}
                  </Grid>
                )
              }

              {
                loading &&
                Array.from({ length: loadingElements }, (_, i) =>
                  <Grid item xs={12} md={6} lg={6} key={'empty_' + i}>
                    <Skeleton sx={{ height: 269 }} />
                  </Grid>
                )
              }

              {matched && matched.map((obj, i) =>
                <Grid key={'matched_' + i} xs={12} md={6} lg={6} item>
                  <Fade in={true} timeout={450}>
                    <Card variant='outlined'>
                      <CardActionArea onClick={() => handlePreview(obj.ptf.id)}>
                        <CardHeader
                          sx={{ pb: 0 }}
                          title={<Typography variant='body1' style={{ fontWeight: 500 }} color={'primary'}>{obj.ptf.name}</Typography>}
                        />
                        <CardContent sx={{ pt: 0 }}>
                          <Box sx={{ display: 'flex' }}>
                            <Box sx={{ flex: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                <Typography variant='h3' className={'big'}>{numeral(obj.ptf.totalReturn).format('+0,000.00')}</Typography>
                                <Typography sx={{ ml: 0 }} variant='h5' className={'big'} >%</Typography>
                              </Box>
                              <Typography variant='caption'>Portfolio return</Typography>
                            </Box>
                            <Box sx={{ flex: '60%', px: 2 }}>
                              <ChartMini isLoading={false} performances={obj.eql}></ChartMini>
                            </Box>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Fade>
                </Grid>
              )}
            </Grid>

          </Grid>
          <PortfolioPreview
            id={selectedPreview}
            open={previewOpen}
            questions={questions}
            handleClose={handlePreviewClose}
          />
        </Container >
      </div>
    </Fade>
  );
}

export default connect()(Builder);
