import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardHeader } from "@mui/material";
import { Trans } from 'react-i18next';
import api from '../services/api';
import numeral from 'numeral';
import { t } from 'i18next';
import moment from 'moment';

const MzMyCapital = ({ idPortfolio,  idAssignment }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    api.get(`v2/mz/wa/desk/portfolios/${idPortfolio}/uid/${idAssignment}/myreturn`)
      .then(response => {
        const data = response.data;
        setData(data);
        localStorage.setItem("TITLE", data.name);
      }).catch(error => {
        console.log(error);
      });
  }, []);

  const formatNumber = (val) => {
    const ret = numeral(val).format('0,000')
    return ret
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={<Typography gutterBottom variant="overline" component="h2">{t('portfolioStat_CapCurr')}</Typography>}
      />
      <CardContent>
        <Typography gutterBottom variant="overline" component="h2">
          {localStorage.getItem('MZPFN')}
        </Typography>
        <Typography className='big' variant="h3" component="h3">
          {data ? formatNumber(data.currentCapital) + ' €' : '-'}
        </Typography>
        <Typography gutterBottom variant="caption" color={'secondary'} component="div">
          {`${t('portfolioStat_CapInit2')} ${moment(data.inception, 'YYYYMMDD').format('DD/MM/YYYY')}: ${formatNumber(data.initialCapital)} € `}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MzMyCapital;
