import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardHeader } from "@mui/material";
import { Trans } from 'react-i18next';
import api from '../services/api';
import numeral from 'numeral';
import moment from 'moment';
import { t } from 'i18next';

const MzInfo = ({ id }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`v2/mz/wa/report/${id}`);
        setData(response.data);
        localStorage.setItem("TITLE", response.data.name);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
      localStorage.removeItem("TITLE");
    };
  }, [id]);

  return (
    <Card variant="outlined">
      <CardHeader
        title={<Typography gutterBottom variant="overline" component="h2">{t('mz_performance_label_1Y')}</Typography>}
      />
      <CardContent>
        <Typography gutterBottom variant="overline" component="h2">
          {localStorage.getItem('MZPFN')}
        </Typography>
        <Typography className='big' variant="h3" component="h3">
          {data ? numeral(data.return_1y).format('+0,000.[00]') + '%' : '-'}
        </Typography>
        <Typography gutterBottom variant="caption" color={'secondary'} component="div">
          <Trans>Last year return</Trans>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MzInfo;
